import { z } from 'zod';

export const WDLinkedBrandSchema = z.object({
  linkedBrand: z.string()
});

export type WDLinkedBrand = z.infer<typeof WDLinkedBrandSchema>;

export const WDBrandSchema = z.object({
  appName: z.string(),
  brandName: z.string(),
  brandLogo: z.string().nullable(),
  navLogo: z.string().nullable(),
  themeName: z.string(),
  brandPath: z.string(),
  apps: z.array(z.string()).optional().nullable()
});

export type WDBrand = z.infer<typeof WDBrandSchema>;

export const WDBrandFileSchema = z.union([WDLinkedBrandSchema, WDBrandSchema]);

export type WDFileBrand = z.infer<typeof WDBrandFileSchema>;

export const WDBrandUpdateSchema = z.intersection(WDBrandSchema, z.object({
  brandLogoRaw: z.string().nullable().optional(),
  navLogoRaw: z.string().nullable().optional()
}));

export type WDBrandUpdate = z.infer<typeof WDBrandUpdateSchema>;
