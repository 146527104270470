/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* libColor.ts based on code from w3color.js */

import IPageColors from '../types/IPageColors';
import { IThemeColors, IThemeColorsKey } from '../types/IThemeColors';
import { hashCode } from './libString';

export function rgbToHsl (r: number, g: number, b: number) {
  let min; let max; let i; let s; let maxcolor; let h; const rgb = [];
  rgb[0] = r / 255;
  rgb[1] = g / 255;
  rgb[2] = b / 255;
  min = rgb[0];
  max = rgb[0];
  maxcolor = 0;
  for (i = 0; i < rgb.length - 1; i++) {
    if (rgb[i + 1] <= min) { min = rgb[i + 1]; }
    if (rgb[i + 1] >= max) { max = rgb[i + 1]; maxcolor = i + 1; }
  }
  if (maxcolor === 0) {
    h = (rgb[1] - rgb[2]) / (max - min);
  }
  if (maxcolor === 1) {
    h = 2 + (rgb[2] - rgb[0]) / (max - min);
  }
  if (maxcolor === 2) {
    h = 4 + (rgb[0] - rgb[1]) / (max - min);
  }
  if (h === undefined || isNaN(h)) { h = 0; }
  h = h * 60;
  if (h < 0) { h = h + 360; }
  const l = (min + max) / 2;
  if (min === max) {
    s = 0;
  } else {
    if (l < 0.5) {
      s = (max - min) / (max + min);
    } else {
      s = (max - min) / (2 - max - min);
    }
  }
  return { h, s, l };
}

export const generateGradientOfColorsHex = (hexColor: string): string[] => {
  const { r, g, b } = convertHexToRGB(hexColor);
  return generateGradientOfColorsRGB(r, g, b).map((c) => rgbToHex(c.r, c.g, c.b));
};

export const generateGradientOfColorsRGB = (r: number, g: number, b: number): Array<{ r: number; g: number; b: number }> => {
  const { h, s, l } = rgbToHsl(r, g, b);
  return generateGradientOfColorsHSL(h, s, l).map((c) => hslToRgb(c.h, c.s, c.l));
};

export const generateGradientOfColorsHSL = (h: number, s: number, l: number): Array<{ h: number; s: number; l: number }> => {
  return [...Array(10).keys()].map((k) => {
    return {
      h,
      s,
      l: ((0.075 * k) + 0.25)
    };
  });
};

export const hslToRgb = (h: number, s: number, l: number) => {
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return {
    r: 255 * f(0),
    g: 255 * f(8),
    b: 255 * f(4)
  };
};

export const rgbToHex = (r: number, g: number, b: number) => {
  const cth = (c: number) => {
    const hex = Math.round(c).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  return `${cth(r)}${cth(g)}${cth(b)}`;
};

export const convertHexToRGB = (hexColor: string) => {
  if (hexColor.length !== 6) throw Error(`Only 6 char hex colors are supported. (${hexColor})`);

  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

const numberToPercent = (n: number) => Math.round(n * 100).toString();

export const generateStyleVarsFromColors = (colors: IThemeColors) => {
  return Object.keys(colors).reduce<string>((str, colorName) => {
    const key = colorName as IThemeColorsKey;
    const { r, g, b } = convertHexToRGB(colors[key]);
    const { h, s, l } = rgbToHsl(r, g, b);
    return str + `
    --wdc-${colorName}-h: ${Math.round(h)};
    --wdc-${colorName}-s: ${numberToPercent(s)}%;
    --wdc-${colorName}-l: ${numberToPercent(l)}%;
    `;
  }, '');
};

export const generateTextStyleVarsFromColors = (textColors: IThemeColors) => {
  return Object.keys(textColors).reduce<string>((str, colorName) => {
    const key = colorName as IThemeColorsKey;
    const { r, g, b } = convertHexToRGB(textColors[key]);
    const { h, s, l } = rgbToHsl(r, g, b);
    return str + `
    --wdc-${colorName}-text-h: ${Math.round(h)};
    --wdc-${colorName}-text-s: ${numberToPercent(s)}%;
    --wdc-${colorName}-text-l: ${numberToPercent(l)}%;
    `;
  }, '');
};

const COLOR_VAR_REGEX = /\{([a-zA-Z]+)\}/;

const colorValueReplace = (color: string, themeColors: IThemeColors) => {
  const match = COLOR_VAR_REGEX.exec(color);
  if (match != null) {
    const key = match[1] as IThemeColorsKey;
    return themeColors[key];
  }
  return color;
};

export const generateStyleVarsFromPageColors = (colors: IPageColors, themeColors: IThemeColors) => {
  return Object.keys(colors).reduce<string>((str, colorName) => {
    const key = colorName as keyof IPageColors;
    const color = colorValueReplace(colors[key], themeColors);
    const { r, g, b } = convertHexToRGB(color);
    const { h, s, l } = rgbToHsl(r, g, b);
    return str + `
    --wdc-${colorName}-h: ${Math.round(h)};
    --wdc-${colorName}-s: ${numberToPercent(s)}%;
    --wdc-${colorName}-l: ${numberToPercent(l)}%;
    `;
  }, '');
};

export function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map(function(v) {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const getBestTextColorForBackgound = (bgColor: string) => {
  const { r, b, g } = convertHexToRGB(bgColor);
  const bgLuminance = luminance(r, b, g);
  return bgLuminance < 0.5 ? 'white' : 'black';
};

export const getRandomThemeColor = (themeColors: IThemeColorsKey[], randomKey: string): IThemeColorsKey => {
  const randomHash = Math.abs(hashCode(randomKey));
  const themeColor = themeColors[randomHash % themeColors.length];
  return themeColor;
};
