import ITheme from '../types/ITheme';

export type ThemeName = 'whitedog' | 'whitedog_green' | 'burgundy' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | 'light' | 'dark';

type IThemes = {
  [name in ThemeName]: ITheme;
};

const dataendureRed = '7a303f';
const white = 'ffffff';
const black = '000000';

const blue = '0E77BB';
const gray = 'e0e0e0';
const darkGray = '1f1f1f';
const lightGray = 'fafafa';
// 01bc86
// 00bc86
// 5d47fc

const DEFAULT_THEME: ITheme = {
  themeName: 'Default',
  avatarColors: ['secondary', 'info', 'success', 'warning', 'danger'],
  colors: {
    primary: blue,
    secondary: gray,
    info: 'ffe69c',
    success: '49cea6',
    warning: 'fd9843',
    danger: 'e04c59',
    dark: darkGray,
    light: lightGray,
    nav: '5d47fd',
    critical: '8979fd',
    high: 'e04c59',
    medium: 'fd9843',
    low: 'ffcd39'
  },
  lightPageColors: {
    background: lightGray,
    background2: 'f5f5f5',
    background3: 'f0f0f0',
    foreground: darkGray,
    hover: 'c8c8c8',
    selected: 'afafaf',
    quickNav: '{nav}',
    quickNavHover: '{nav}',
    cardbg: '212529'
  },
  darkPageColors: {
    background: darkGray,
    background2: '141414',
    background3: '0a0a0a',
    foreground: lightGray,
    hover: '797979',
    selected: '4c4c4c',
    quickNav: '{nav}',
    quickNavHover: '{nav}',
    cardbg: '141619'
  },
  textColors: {
    primary: white,
    secondary: black,
    info: white,
    success: black,
    warning: white,
    danger: white,
    dark: white,
    light: black,
    nav: white,
    critical: white,
    high: white,
    medium: white,
    low: white
  }
};

const getThemeFromDefault = (overrides: Partial<ITheme>): ITheme => {
  return Object.assign({}, DEFAULT_THEME, overrides);
};

export const THEMES: IThemes = {
  whitedog: getThemeFromDefault({
    themeName: 'WhiteDog Purple',
    colors: {
      ...DEFAULT_THEME.colors,
      primary: '5d47fc'
    }
  }),
  whitedog_green: getThemeFromDefault({
    themeName: 'WhiteDog Green',
    colors: {
      ...DEFAULT_THEME.colors,
      primary: '5d47fc',
      nav: '00bc86'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: black
    }
  }),
  burgundy: getThemeFromDefault({
    themeName: 'Burgundy',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: dataendureRed,
      primary: '00a0af'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: white
    }
  }),
  red: getThemeFromDefault({
    themeName: 'Red',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: 'c2202e'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: white
    }
  }),
  orange: getThemeFromDefault({
    themeName: 'Orange',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: 'fd7e14'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: black
    }
  }),
  yellow: getThemeFromDefault({
    themeName: 'Yellow',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: 'f4b601'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: black
    }
  }),
  green: getThemeFromDefault({
    themeName: 'Green',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: '45926B'
    }
  }),
  blue: getThemeFromDefault({
    themeName: 'Blue',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: '00439c'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: white
    }
  }),
  purple: getThemeFromDefault({
    themeName: 'Purple',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: '6837d1'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: white
    }
  }),
  light: getThemeFromDefault({
    themeName: 'Light',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: gray
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: black
    },
    lightPageColors: {
      ...DEFAULT_THEME.lightPageColors,
      quickNav: darkGray,
      quickNavHover: '{primary}'
    },
    darkPageColors: {
      ...DEFAULT_THEME.darkPageColors,
      quickNav: lightGray,
      quickNavHover: '{primary}'
    }
  }),
  dark: getThemeFromDefault({
    themeName: 'Dark',
    colors: {
      ...DEFAULT_THEME.colors,
      nav: '0b0a0d'
    },
    textColors: {
      ...DEFAULT_THEME.textColors,
      nav: white
    },
    lightPageColors: {
      ...DEFAULT_THEME.lightPageColors,
      quickNav: darkGray,
      quickNavHover: '{primary}'
    },
    darkPageColors: {
      ...DEFAULT_THEME.darkPageColors,
      quickNav: lightGray,
      quickNavHover: '{primary}'
    }
  })
};
