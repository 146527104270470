import React, { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useQuery } from '@tanstack/react-query';

import { ThemeName, THEMES } from '../data/themes';
import {
  generateStyleVarsFromColors, generateStyleVarsFromPageColors, generateTextStyleVarsFromColors
} from '../libs/libColor';
import useLocalStorageBackedState from '../libs/useLocalStorageBackedState';
import Loading from '../pages/Loading';
import IPageColors from '../types/IPageColors';
import ITheme from '../types/ITheme';
import ThemeType from '../types/ThemeType';
import { WDBrand, WDBrandSchema, WDLinkedBrandSchema } from '../types/WDBrand';

export interface IBrandContext {
  brandRef: string;
  setBrandRef: (brandRef: string) => void;
  brand: WDBrand;
  theme: ITheme;
  setTheme: (theme: ThemeName | null) => void;
  themeType: ThemeType;
  setThemeType: (type: ThemeType | null) => void;
}

const BrandContext = createContext<IBrandContext | null>(null);

BrandContext.displayName = 'BrandContext';

interface BrandContextProviderProps {
  brandRef: string;
  children: React.ReactNode;
  themeType?: ThemeType;
}

const fetchBrand = async (brandRef: string): Promise<WDBrand> => {
  const response = await fetch(`${import.meta.env.VITE_STATIC_URI}/brands/${brandRef}/brand.json`, {
    method: 'GET',
    cache: 'no-cache'
  });
  if (response.ok) {
    const data = await response.json();
    const linkedBrand = await WDLinkedBrandSchema.safeParseAsync(data);
    if (linkedBrand.success) {
      return await fetchBrand(linkedBrand.data.linkedBrand);
    }
    const brand: WDBrand = await WDBrandSchema.parseAsync(data);
    return brand;
  }
  return await fetchBrand('my.whitedog.app');
};

const getDefaultPageColors = (context: IBrandContext): IPageColors => {
  switch (context.themeType) {
    case ThemeType.Dynamic:
    case ThemeType.Light:
      return context.theme.lightPageColors;
    case ThemeType.Dark:
      return context.theme.darkPageColors;
  }
};

const getDarkPageColors = (context: IBrandContext): IPageColors => {
  switch (context.themeType) {
    case ThemeType.Light:
      return context.theme.lightPageColors;
    case ThemeType.Dynamic:
    case ThemeType.Dark:
      return context.theme.darkPageColors;
  }
};

export const BrandContextProvider: React.FC<BrandContextProviderProps> = (props) => {
  const [overrideTheme, setOverrideTheme] = useState<ThemeName | null>(null);
  const [overrideThemeType, setOverrideThemeType] = useLocalStorageBackedState<ThemeType | null>('overrideThemeType', props.themeType ?? null);

  const [brandRef, setBrandRef] = useState(props.brandRef);

  const { data } = useQuery(['brand', brandRef], async () => {
    const brandData = await fetchBrand(brandRef);

    return brandData;
  }, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 8 * 60 * 60 * 1000
  });

  if (data === undefined) return <Loading fullScreen={true} />;

  const brandTheme = overrideTheme ?? data.themeName as ThemeName;
  const theme = Object.keys(THEMES).includes(brandTheme) ? THEMES[brandTheme] : THEMES.whitedog;

  const brandContext: IBrandContext = {
    brandRef,
    setBrandRef: (brandRef) => setBrandRef(brandRef),
    brand: data,
    theme,
    setTheme: setOverrideTheme,
    themeType: props.themeType ?? overrideThemeType ?? ThemeType.Dynamic,
    setThemeType: setOverrideThemeType
  };

  return <BrandContext.Provider value={brandContext}>
    <Helmet>
      <style>
        {`:root {
          ${generateStyleVarsFromColors(brandContext.theme.colors)}
          ${generateTextStyleVarsFromColors(brandContext.theme.textColors)}
          ${generateStyleVarsFromPageColors(getDefaultPageColors(brandContext), brandContext.theme.colors)}
        }
        
        @media (prefers-color-scheme: dark) {
          :root {
            ${generateStyleVarsFromPageColors(getDarkPageColors(brandContext), brandContext.theme.colors)}
          }
        }`}
      </style>
    </Helmet>
    {props.children}
  </BrandContext.Provider>;
};

export const useBrandContext = (): IBrandContext => {
  const context = useContext(BrandContext);

  if (context === null) {
    throw Error('Must be used within BrandContextProvider');
  }

  return context;
};
