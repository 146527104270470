import React from 'react';

interface LoadingSpinnerProps extends React.HTMLAttributes<HTMLElement> {

}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  const { className, ...otherProps } = props;
  return <div>
    <div className={`rotate-y ${className ?? ''}`} {...otherProps}>
      <svg className={`fill-foreground ${className ?? ''}`}>
          <use href={'/icons.svg#wdc-dog'} />
        </svg>
    </div>
  </div>;
};

export default LoadingSpinner;
